import { NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { TransitionState } from "src/standard/ts/formUtils";

@Component({
  standalone: true,
  selector: "standard-lazy-component",
  template: `
    @if (transitionState !== "idle") {
      <ng-container *ngTemplateOutlet="skeletonTemplate" />
    }
    <ng-template #lazyContent />
  `,
  imports: [NgTemplateOutlet],
})
export class StandardLazyComponent<
  TContent,
  TLazyProps extends Record<string, unknown>,
> implements AfterViewInit
{
  _content: Type<TContent> | undefined;
  @Input({ required: true }) set content(
    ContentComponent: Type<TContent> | undefined,
  ) {
    this._content = ContentComponent;
  }

  _lazyProps: TLazyProps | undefined;
  @Input() set lazyProps(newVal: TLazyProps | undefined) {
    if (this._instance) {
      this.propagateLazyProps(this._lazyProps, newVal);
    }

    this._lazyProps = newVal;
  }
  get lazyProps() {
    return this._lazyProps;
  }

  @ContentChild("skeleton", { descendants: false })
  skeletonTemplate: TemplateRef<never> | null = null;

  @ViewChild("lazyContent", { read: ViewContainerRef })
  lazyContent: ViewContainerRef | undefined;

  protected transitionState: TransitionState = "loading";
  private _instance: TContent | undefined;

  ngAfterViewInit() {
    setTimeout(() => {
      this.render();
    });
  }

  render() {
    const ContentComponent = this._content;
    if (!ContentComponent) {
      return;
    }

    const lazyContent = this.lazyContent;
    if (!lazyContent) {
      return;
    }

    this.transitionState = "loading";

    lazyContent.clear();
    const componentRef = lazyContent.createComponent(ContentComponent);
    this._instance = componentRef.instance;

    this.propagateLazyProps(undefined, this.lazyProps);

    this.transitionState = "idle";
  }

  propagateLazyProps(
    prev: TLazyProps | undefined,
    newProps: TLazyProps | undefined,
  ) {
    if (this.transitionState === "idle") {
      return;
    }

    if (!this._instance) {
      return;
    }

    const prevKeys = Object.keys(prev ?? {});
    const newKeys = Object.keys(newProps ?? {});
    const uniqueKeys = prevKeys
      .filter((t) => !newKeys.includes(t))
      .concat(newKeys);

    // eslint-disable-next-line
    const instance = this._instance as any;

    for (const key of uniqueKeys) {
      const prevVal = prev?.[key];
      const newVal = newProps?.[key];

      // Set to new value if it's set
      if (newVal !== undefined) {
        // eslint-disable-next-line
        if (instance[key] !== newVal) {
          // eslint-disable-next-line
          instance[key] = newVal;
        }
      }
      // Otherwise, clear out field value
      else if (prevVal !== undefined) {
        // eslint-disable-next-line
        instance[key] = undefined;
      }
    }
  }
}
