import { NgTemplateOutlet } from "@angular/common";
import { Component, OnInit, Type } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  ResolveEnd,
  Router,
  RouterOutlet,
} from "@angular/router";
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionStatus,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { DataService, DataType, NamingConvention } from "breeze-client";
import { DialogService } from "primeng/dynamicdialog";
import { Subject, filter, takeUntil } from "rxjs";
import { StandardLogService } from "src/standard/logging/logging";
import { StandardLazyComponent } from "src/standard/standard-lazy-component/standard-lazy-component.component";
import { StandardNavShellComponent } from "src/standard/standard-nav-shell/standard-nav-shell.component";
import NProgress from "src/standard/ts/nprogress-customizations";
import { UserSummary } from "src/standard/ts/types";
import { Profile } from "src/typings";
import invariant from "tiny-invariant";
import { ServerSidePublicConfigDataService } from "./services/server-side-config-data/server-side-public-config-data.service";
import Utilities from "./services/utilities";

declare global {
  // eslint-disable-next-line no-var
  var __NG_DIALOG_SERVICE__: DialogService | undefined;
}

export function init(
  serverSidePublicConfigDataService: ServerSidePublicConfigDataService,
) {
  return async () => {
    await serverSidePublicConfigDataService.loadServerSidePublicData();

    return new Promise((resolve, reject) => {
      NamingConvention.camelCase.setAsDefault();
      DataType.parseDateFromServer = function parseDateFromServer(
        source: string,
      ) {
        if (!source.endsWith("Z") && !source.endsWith("z")) {
          source = source + "Z";
        }

        return new Date(source);
      };

      /*
			(<any>breeze.EntityType.prototype).getEntityCtor = function () {
				var origCtor = this.getEntityCtor.apply(this);
		
				var rightsProp = this.getDataProperty("rights");
				if (!rightsProp) {
					this.addProperty(new breeze.DataProperty(this.rights));
					rightsProp = this.getDataProperty("rights");
				}
		
				if (rightsProp.isUnmapped) {
					rightsProp.isUnmapped = false;
				}
		
				return origCtor;
			};
			*/

      // Breeze does a bad thing and uses substr(-1) instead of slice(-1)
      // that is browser inconsistent.  This implementation overrides the
      // default behavior
      // eslint-disable-next-line
      (<any>DataService)._normalizeServiceName = function (
        serviceName: string,
      ) {
        serviceName = serviceName.trim();
        if (serviceName.slice(-1) !== "/") {
          return serviceName + "/";
        } else {
          return serviceName;
        }
      };

      resolve(undefined);
    });
  };
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  invariant(window.__settings, "No settings set");
  invariant(window.__settings.adTenantId, "No AdTenantId set");
  invariant(window.__settings.adClientId, "No AdClientId set");

  return new PublicClientApplication({
    auth: {
      clientId: window.__settings.adClientId,
      authority: `https://login.microsoftonline.com/${window.__settings.adTenantId}/`,
      redirectUri: `https://${document.location.host}/`,
      postLogoutRedirectUri: `https://${document.location.host}/`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  invariant(window.__settings, "No settings set");
  invariant(window.__settings.adClientId, "No AdClientId set");

  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
    "user.read",
  ]);
  protectedResourceMap.set(`https://${document.location.host}/`, [
    `${window.__settings.adClientId}/user_impersonation`,
  ]);

  protectedResourceMap.set(`https://${document.location.host}/api/`, [
    `${window.__settings.adClientId}/user_impersonation`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@Component({
  standalone: true,
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
  imports: [
    NgTemplateOutlet,
    RouterOutlet,

    StandardLazyComponent,
    StandardNavShellComponent,
  ],
})
export class AppComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor(
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private logService: StandardLogService,
  ) {
    void import("./nav-content/nav-content.component").then((res) => {
      this.navContent = res.default;
    });

    router.events.subscribe((event): void => {
      if (event instanceof ResolveEnd) {
        NProgress.start();
      } else if (event instanceof NavigationEnd) {
        NProgress.done();
        logService.logPageView(document.title, event.url);
      } else if (event instanceof NavigationCancel) {
        NProgress.done();
      }
    });
  }

  destroy$ = new Subject<void>();
  initializing: boolean = true;
  userSummary: UserSummary | undefined;
  navContent: Type<unknown> | undefined;

  ngOnInit(): void {
    this.initializing = true;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        const account: Profile | undefined =
          this.msalService.instance.getAllAccounts()[0];
        if (account) {
          const userSummary = {
            name: account.idTokenClaims?.name ?? "",
          };
          this.userSummary = userSummary;
        }

        Utilities.hideLoadingScreen();
        this.initializing = false;
      });
  }

  ngOnDestory(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
