import { Routes } from "@angular/router";
import { AppGuard } from "./guards/app-guard/app.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "license-expired",
    loadComponent: () =>
      import(
        "src/standard/pages/error-pages/license-expired/license-expired.component"
      ),
    canActivate: [AppGuard],
  },
  {
    path: "maintenance",
    loadComponent: () =>
      import(
        "src/standard/pages/error-pages/maintenance/maintenance.component"
      ),
  },
  {
    path: "access-denied",
    loadComponent: () =>
      import(
        "src/standard/pages/error-pages/access-denied/access-denied.component"
      ),
  },
  {
    path: "",
    canActivate: [AppGuard],
    children: [
      {
        path: "home",
        loadChildren: () => import("./home/routes"),
      },
      {
        path: "chats",
        loadChildren: () => import("./chats/routes"),
      },
      {
        path: "translations",
        loadChildren: () => import("./translators/routes"),
      },
      {
        path: "gpt-analytics",
        loadChildren: () => import("./gpt-analytics/routes"),
      },
      {
        path: "ai-assistants",
        loadChildren: () => import("./gpt-docsearch/routes"),
      },
      {
        path: "analytics",
        loadChildren: () => import("./analytics/routes"),
      },
      {
        path: "terms",
        loadChildren: () => import("./terms/routes"),
      },
      {
        path: "admin",
        loadChildren: () => import("./admin/routes"),
      },
      {
        path: "development",
        loadChildren: () => import("src/standard/pages/development/routes"),
      },
      {
        path: "",
        loadChildren: () => import("src/standard/pages/error-pages/routes"),
      },
    ],
  },
];

export default routes;
